/* You can add global styles to this file, and also import other style files */

// Set the variables here.

.numbers {
  position: absolute;
  bottom: 0;

  .number {
    font-weight: 300;
    font-size: 56px;
    line-height: 75px;
    font-style: normal;
  }

  .title {
    font-size: 1rem;
  }
}

$base-text: #212529;
$red: #c61c18 !default;

span.navigation-icon {
  color: #212529;
  font-size: 2rem;
}

.section-background {
  background-color: #f8f9fa;
}

.section-header-text {
  width: 96px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  color: #c61c18;
}

.settings-submenu-header {
  border-bottom: 2px solid #e9ecef;
}

div.instructor-container {
  background: #f8f9fa;
  border-radius: 8px;
  min-height: 75vh;
}

.section-header-text {
  font-size: 1.5rem;
  color: #c61c18;
}

//@import "@progress/kendo-theme-bootstrap/dist/all.scss";
// kendo-angular-buttons
@import "@progress/kendo-theme-bootstrap/scss/button/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/chip/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/fab/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/action-buttons/_index.scss";

// kendo-angular-charts
//@import "@progress/kendo-theme-bootstrap/scss/dataviz/_index.scss";

// kendo-angular-conversational-ui
//@import "@progress/kendo-theme-bootstrap/scss/chat/_index.scss";

// kendo-angular-dateinputs
//@import "@progress/kendo-theme-bootstrap/scss/dateinput/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/datepicker/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/daterangepicker/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/datetimepicker/_index.scss";
//Added Imports
@import "@progress/kendo-theme-bootstrap/scss/timepicker/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/calendar/_index.scss";

// kendo-angular-dialog
@import "@progress/kendo-theme-bootstrap/scss/dialog/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/window/_index.scss";

// kendo-angular-dropdowns
//@import "@progress/kendo-theme-bootstrap/scss/autocomplete/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/combobox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/dropdownlist/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/dropdowntree/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/multiselect/_index.scss";

// kendo-angular-grid
@import "@progress/kendo-theme-bootstrap/scss/grid/_index.scss";

// kendo-angular-editor
//@import "@progress/kendo-theme-bootstrap/scss/editor/_index.scss";

// kendo-angular-icons
@import "@progress/kendo-theme-bootstrap/scss/icons/_index.scss";

// kendo-angular-indicators
//@import "@progress/kendo-theme-bootstrap/scss/badge/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/loader/_index.scss";

// kendo-angular-inputs
@import "@progress/kendo-theme-bootstrap/scss/checkbox/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/colorpicker/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/forms/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/input/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/maskedtextbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/numerictextbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/radio/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/slider/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/switch/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/textarea/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/textbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/validator/_index.scss";

// kendo-angular-layout
@import "@progress/kendo-theme-bootstrap/scss/avatar/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/card/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/drawer/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/expansion-panel/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/panelbar/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/splitter/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/stepper/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/tabstrip/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/tilelayout/_index.scss";

// kendo-angular-menu
//@import "@progress/kendo-theme-bootstrap/scss/menu/_index.scss";

// kendo-angular-navigation
@import "@progress/kendo-theme-bootstrap/scss/appbar/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/breadcrumb/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/bottom-navigation/_index.scss";

// kendo-angular-notification
@import "@progress/kendo-theme-bootstrap/scss/notification/_index.scss";

// kendo-angular-pager
//@import "@progress/kendo-theme-bootstrap/scss/pager/_index.scss";

// kendo-angular-popup
//@import "@progress/kendo-theme-bootstrap/scss/popup/_index.scss";

// kendo-angular-progressbar
//@import "@progress/kendo-theme-bootstrap/scss/progressbar/_index.scss";

// kendo-angular-ripple
//@import "@progress/kendo-theme-bootstrap/scss/ripple/_index.scss";

// kendo-angular-scrollview
//@import "@progress/kendo-theme-bootstrap/scss/scrollview/_index.scss";

// kendo-angular-toolbar
//@import "@progress/kendo-theme-bootstrap/scss/toolbar/_index.scss";

// kendo-angular-tooltip
//@import "@progress/kendo-theme-bootstrap/scss/tooltip/_index.scss";

// kendo-angular-treelist
//@import "@progress/kendo-theme-bootstrap/scss/treelist/_index.scss";

// kendo-angular-treeview
//@import "@progress/kendo-theme-bootstrap/scss/treeview/_index.scss";

// kendo-angular-upload
//@import "@progress/kendo-theme-bootstrap/scss/dropzone/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/upload/_index.scss";

//OTHERS
@import "@progress/kendo-theme-bootstrap/scss/utils/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/action-sheet/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/adaptive/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/captcha/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/color-preview/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/colorpalette/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/cursor/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/dropdowngrid/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/filemanager/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/filter/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/floating-label/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/gantt/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/imageeditor/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/list/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/listbox/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/listgroup/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/listview/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/map/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/mediaplayer/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/messagebox/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/orgchart/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/overlay/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/pdf-viewer/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/pivotgrid/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/popover/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/rating/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/responsivepanel/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/scheduler/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/styling/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/table/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/taskboard/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/timeline/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/typography/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/virtual-scroller/_index.scss";
//@import "@progress/kendo-theme-bootstrap/scss/wizard/_index.scss";

@import "bootstrap/scss/bootstrap.scss";

body {
  background-color: #e5e5e5;
  font-family: "Roboto", sans-serif;
}

.class-container {
  background: #f8f9fa;
}

.add-cursor-pointer {
  cursor: pointer;
}

.badge {
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 0.6rem;
}

.badge-active {
  background: #198754;
}

.badge-past-due {
  background: #dc3545;
}

.badge-cancelled {
  background: #ffc107;
  color: #212529;
}

.badge-none {
  background: #212529;
}

.badge-trial {
  background: #0658a5;
}

.badge-text {
  font-size: 0.8rem !important;
}

.separator {
  height: 2px;
  background-color: #e5e5e5;
}

/* Class list date cards style */
.attendance-section {
  .label-header {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.class-date-container {
  cursor: pointer;
  border-color: #ced4da;

  .class-date {
    color: #212529;
    font-weight: 300;
    font-size: 5rem;
  }

  .class-day {
    font-size: 2rem;
    color: #212529;
  }

  .class-month-year {
    font-size: 1rem;
  }

  .attendance-count {
    background-color: #dee2e6;
    color: #212529;
    font-size: 1rem;
  }
}

.class-date-container:hover,
.class-date-container:focus,
.class-date-container-selected {
  background-color: #495057;
  color: #ffffff;
  border-color: #495057 !important;

  .class-date,
  .class-day,
  .class-month-year {
    color: #ffffff;
  }

  .attendance-count {
    background-color: #ffffff;
    color: #212529;
    font-size: 1rem;
  }
}

.class-static-info {
  border-style: solid;
  border-width: 0 2px 0 2px;
  border-color: #e9ecef;
}

p.sessions-logged-count {
  font-size: 1.5rem;
}

p.sessions-logged-label {
  font-size: 1.1rem;
  color: #6c757d;
}

span.navigation-icon {
  color: #212529;
  font-size: 2rem;
}
